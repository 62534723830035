<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <!-- <Button
          label="اضافة جديد"
          icon="pi pi-plus" v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('installationsAdd')"
          @click="$router.push('installations/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash" v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('installationsDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه أوامر التركيب
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <Column field="contractsId.code" header="رقم العقد" :sortable="true">
        <template #filter>
          <InputText
            type="number"
            v-model="filters['contractsId.code']"
            class="p-column-filter"
            placeholder="بحث برقم العقد"
          />
        </template>
      </Column>
      <!-- <Column
        field="contractsId.clientsId.code"
        header="كود العميل"
        :sortable="true"
      /> -->
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
      >
        <template #filter>
          <Dropdown
            :options="clientsList"
            class="form-select form-control text-right"
            style="direction:rtl"
            optionLabel="name"
            optionValue="name"
            :filter="true"
            v-model="filters['contractsId.clientsId.name']"
            placeholder="بحث باسم العميل"
            :showClear="true"
          />
          <!-- <select
            class="form-select form-control p-column-filter"
            id="clientsId"
            name="clientsId"
            v-model="filters['contractsId.clientsId.name']"
            placeholder="بحث باسم العميل"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of clientsList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select> -->
        </template>
      </Column>
      <Column
        field="contractsId.branchesId.name"
        header="اسم الفرع"
        :sortable="true"
      >
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="branchesId"
            name="branchesId"
            v-model="filters['contractsId.branchesId.name']"
            placeholder="بحث بأسم الفرع"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of branchesList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>

      <Column field="contractsId.date" header="تاريخ العقد" :sortable="true">
        <template #filter>
          <InputText
            type="date"
            v-model="filters['contractsId.date']"
            class="p-column-filter"
            placeholder="بحث بتاريخ العقد"
          />
        </template>
      </Column>
      <Column
        field="id"
        header="الحاله"
        :sortable="true"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          {{
            slotProps.data.contractsId &&
            slotProps.data.contractsId.installationsLogs &&
            slotProps.data.contractsId.installationsLogs.length > 0
              ? $getStatus(
                  slotProps.data.contractsId.installationsLogs[0].status,
                )
              : $getStatus(slotProps.data.status)
          }}
        </template>
      </Column>

      <Column
        field="id"
        header="التسليم"
        v-if="$checkRoles('installtionsToDelivery')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            type="button"
            v-tooltip="'تسليم'"
            :disabled="ddd"
            v-if="
              !slotProps.data.contractsId.productsList.find(
                (el) => el.isReady == false,
              ) && !slotProps.data.toDelivery
            "
            @click="
              toDelivery(
                slotProps.data.id,
                slotProps.data.contractsId.id,
                slotProps.data.backDeliveryNote,
              )
            "
            icon="pi pi-reply"
            class="p-button-info p-ml-2  p-button-rounded"
          ></Button>
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data.contractsId]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="deliveryDate" header="اقصى تاريخ تسليم" />
            <Column field="address" header="الموقع" />
            <Column field="note" header="ملاحظات" />
          </DataTable>
          <DataTable
            :value="[slotProps.data]"
            v-if="slotProps.data.backDeliveryNote"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="backDeliveryNote" header="ملاحظات السكيرتاريه" />
          </DataTable>

          <DataTable
            :value="slotProps.data.contractsId.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />

            <Column field="readyDate" header="تاريخ التسليم" />
            <Column field="usersId" header="الموظف المسؤول">
              <template #body="slotProps">
                <p v-if="slotProps.data.usersId">
                  {{ slotProps.data.usersId.name }}
                </p>
                <p v-else>
                  --
                </p>
              </template>
            </Column>
            <Column field="isReady" header="الحاله">
              <template #body="slotProps">
                <span v-if="slotProps.data.isReady">
                  تحت التسليم الفني
                </span>
                <span v-else>
                  {{
                    slotProps.data.usersId
                      ? slotProps.data.status == 'revision'
                        ? 'فى المراجعة'
                        : 'فى التشغيل'
                      : 'تحت التوزيع'
                  }}
                </span>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>

    <Dialog
      header="التحويل الى التسليم"
      :visible.sync="displayBasicToDelivery"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card ">
          <div class="mb-3 ">
            <label for="note" class="form-label">
              ملاحظات
            </label>
            <textarea
              class="form-control"
              type="note"
              id="note"
              v-model="delivery.note"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="حفظ"
          icon="pi pi-check"
          @click="toAddDelivery"
          autofocus
        />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasicToDelivery = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,
      installationsStepsList: [],
      delivery: {
        note: null,
        contractsId: null,
        date: null,
        usersId: null,
        installationsId: null,
        displayBasicToDelivery: false,
      },
      displayBasicToDelivery: false,

      filters: {},
      branchesList: [],
      clientsList: [],

      ddd: false,
    };
  },
  methods: {
    toDelivery(id, contractsId, backDeliveryNote) {
      this.ddd = true;
      const d = new Date();
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

      this.displayBasicToDelivery = true;
      const user = JSON.parse(localStorage.admin);

      this.delivery = {
        contractsId,
        installationsId: id,
        date: `${ye}-${mo}-${da}`,
        usersId: user.id,
        backDeliveryNote,
      };
    },
    toAddDelivery() {
      this.$http.post(`delivery`, this.delivery).then(
        () => {
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التحويل بنجاح',
            life: 3000,
          });
          this.$http
            .put(`installations/toDelivery/${this.delivery.installationsId}`, {
              toDelivery: true,
              status: 'UnderOperation',
              deliveryNote: this.delivery.note,
            })
            .then(() => {
              this.displayBasicToDelivery = false;

              this.$http
                .put(`contracts/addInstalltions/${this.delivery.contractsId}`, {
                  status: 'تحت التسليم',
                })
                .then(() => {
                  this.ddd = false;
                  this.getData();
                });
            });
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    onChange(event, id) {
      if (id) {
        this.$http
          .put(`installations/` + id, {
            installationsStepsId: event.target.value.id,
          })
          .then(
            () => {
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم التعديل بنجاح',
                life: 3000,
              });
              this.getData();
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      }
    },
    getData() {
      if (this.$checkRoles('installationsVeiwAll')) {
        this.$http.get(`installations/getAllData`).then(
          (response) => {
            this.loading = false;
            this.list = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        const user = JSON.parse(localStorage.admin);

        this.$http
          .get(`installations/getAllDataBySectionsId/${user.sectionsId}`)
          .then(
            (response) => {
              this.loading = false;
              this.list = response.data;
            },
            (err) => {
              this.loading = false;
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      }
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    if (!this.$checkRoles('installationsVeiw')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`branches`).then((res) => {
      this.branchesList = res.data;
    });
    this.$http.get(`clients`).then((res) => {
      this.clientsList = res.data;
    });
    this.getData();
  },
};
</script>
